import React from 'react';

import Landing from './sections/landing';
import About from './sections/about';
import TheProblem from './sections/theproblem';
import Solution from './sections/solution';
import Software from './sections/software';
import Software2 from './sections/software2';
import Software3 from './sections/software3';
import Hardware from './sections/hardware';
import Contact from './sections/contact';
import Footer from './sections/footer';
import Videos from './sections/videos';

function App() {
  return (
    <div>
      <Landing />
      <About />
      <TheProblem>
        <Solution />
      </TheProblem>
      <Software />
      <Software2 />
      <Software3 />
      <Videos />
      <Hardware />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
