import styled from "styled-components";

export const Container = styled.div`
  background-image: url(${props => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 135vh;
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 550px) {
    background-image: url(${props => props.bg2});
    height: 100vh;
  }
`;
