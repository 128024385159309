import styled from "styled-components";

export const Container = styled.div`
  background-image: url(${props => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding-top: 450px;
  @media (max-width: 550px) {
    padding-top: 300px;
  }
`;

export const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextInput = styled.input`
  height: 31px;
  font-size: 16px;
  padding-left: 10px;
  width: 400px;
  background-color: #0a0a0a;
  border: none;
  outline: none;
  color: white;
  margin-bottom: 9px;
  box-sizing: border-box;
  @media (max-width: 433px) {
    width: 90vw;
  }
`;

export const TextArea = styled.textarea`
  height: 135px;
  font-size: 16px;
  width: 400px;
  padding-left: 10px;
  border: none;
  background-color: #0a0a0a;
  outline: none;
  color: white;
  margin-bottom: 9px;
  padding: 11px;
  box-sizing: border-box;
  @media (max-width: 433px) {
    width: 90vw;
  }
`;

export const Input = styled.input`
  height: 35px;
  font-weight: 500;
  background-color: #ff6868;
  border: none;
  color: white;
  margin-top: 8px;
  font-size: 17px;
  width: 112px;
  -ms-flex-item-align: end;
  align-self: flex-end;
  transition: all 0.4s;
  outline: none;
`;
