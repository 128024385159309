import React from "react";

import { Container } from "./styles";

import SoftwareBg from "../../assets/softwareBg.jpg";
import Select from "../../assets/screens/select.png";

import Heading from "../../components/heading";
import Breakdown from "../../components/breakdown";

export default function Software() {
  return (
    <Container bg={SoftwareBg}>
      <Heading text={"SOFTWARE"} />

      <Breakdown
        image={Select}
        title={"Training programs"}
        items={[
          {
            heading: "Accuracy",
            text:
              "Ideal for building the mind-muscle connection to place shots precisely. Accuracy is measured on a per-shot basis."
          },
          {
            heading: "Time Trail",
            text:
              "A program designed to put a shooter under pressure. Listen for the buzzer and shoot as many shots as accurately as possible on target."
          },
          {
            heading: "Reflexes",
            text:
              "This program is all about reaction time. After a random delay a buzzer will sound and the shooter must fire a single shot as quickly and accurately as possible."
          }
        ]}
      />
    </Container>
  );
}
