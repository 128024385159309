import React from "react";

import Fade from "react-reveal/Fade";

import {
  Container,
  CardItem,
  Wrapper,
  Topbar,
  Title,
  Icon,
  ListItem,
  ListIcon
} from "./styles";

import Cost from "../../assets/icons/cost.png";
import Safety from "../../assets/icons/safety.png";
import Analysis from "../../assets/icons/analysis.png";
import Dot from "../../assets/icons/dot.png";

const Card = ({ title, icon, points }) => {
  return (
    <CardItem>
      <Topbar>
        <Title>{title}</Title>
        <Icon src={icon} />
      </Topbar>
      {points.map((point, index) => {
        return (
          <ListItem key={index}>
            <ListIcon src={Dot} />
            {point}
          </ListItem>
        );
      })}
    </CardItem>
  );
};

export default function Cards() {
  return (
    <Container>
      <Wrapper>
        <Fade bottom>
          <Card
            title={"Cost"}
            icon={Cost}
            points={[
              "Ammunition",
              "Cost of training",
              "Access to safe and high quality training"
            ]}
          />
        </Fade>
        <Fade bottom>
          <Card
            title={"Safety"}
            icon={Safety}
            points={[
              "Negligent discharges",
              "Unsafe handling of firearms",
              "Eye and ear damage"
            ]}
          />
        </Fade>
        <Fade bottom>
          <Card
            title={"Analysis"}
            icon={Analysis}
            points={["Lack of historical data", "Safety and security of data"]}
          />
        </Fade>
      </Wrapper>
    </Container>
  );
}
