import React, { useState } from "react";

import Fade from "react-reveal/Fade";

import {
  Container,
  InnerWrapper,
  ContactContainer,
  Form,
  TextArea,
  Input,
  TextInput
} from "./styles";
import Bg from "../../assets/contactbg.png";

import Heading from "../../components/heading";

export default function Contact() {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  return (
    <Container bg={Bg}>
      <InnerWrapper>
        <Fade bottom>
          <Heading text={"CONTACT US"} />
        </Fade>
        <ContactContainer>
          <Form
            className="contact-form"
            method="POST"
            action="https://formspree.io/ebrahimm@zans.co.za"
          >
            <TextInput
              type="text"
              required
              className="email-input"
              placeholder="Email"
              value={email}
              type="email"
              name="email"
              onChange={event => setEmail(event.target.value)}
            />

            <TextArea
              required
              value={message}
              placeholder="Message"
              name="message"
              className="message-input"
              onChange={event => setMessage(event.target.value)}
              cols={40}
              rows={10}
            />
            <Input type="submit" className="submit-button" value="Send" />
          </Form>
        </ContactContainer>
      </InnerWrapper>
    </Container>
  );
}
