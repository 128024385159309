import styled from "styled-components";

export const Container = styled.div`
  background-image: url(${props => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 400px;
  margin-bottom: 90px;
`;
