import styled from 'styled-components';

export const Container = styled.div`
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 100px;
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  justify-content: center;
  margin-top: 100px;
`;

export const VideoContainer = styled.video`
  margin: 10px;
  width: 550px;
  height: 300px;

  @media (max-width: 799px) {
    width: 450px;
    height: 200px;
  }
`;
