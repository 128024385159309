import styled from "styled-components";

export const Container = styled.div`
width: 100%:
display: flex;
align-items: center;
justify-content: center;
`;

export const Text = styled.span`
  font-size: 40px;
  font-weight: 800;
  color: #ff6868;
  @media (max-width: 550px) {
    font-size: 30px;
  }
`;
