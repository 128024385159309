import React from 'react';

import { Container } from './styles';

import BG from '../../assets/softwareBg2.jpg';
import Target from '../../assets/screens/target.png';
import Breakdown from '../../components/breakdown';

export default function Software3() {
  return (
    <Container bg={BG}>
      <Breakdown
        image={Target}
        title={'Live-feedback target'}
        items={[
          {
            heading: 'Realistic',
            text: 'Places shots on target as though the shooter was physically shooting at the screen. Provides visible feedback on shot positioning for correction of subsiquent shots.',
          },
          {
            heading: 'Valuable feedback',
            text: 'Provides on the fly metrics such as accuracy, shots fired, accuracy per shot, weapon status and average accuracy.',
          },
        ]}
      />
    </Container>
  );
}
