import React from "react";

import { Container } from "./styles";

import Heading from "../../components/heading";
import HwBreakdown from "../../components/hwBreakdown";
import Gun from "../../assets/gun_isolated2.png";

import BG from "../../assets/softwareBg1.jpg";

export default function Hardware() {
  return (
    <Container bg={BG}>
      <Heading text={"HARDWARE"} />

      <HwBreakdown
        image={Gun}
        items={[
          {
            heading: "Self-contained",
            text:
              "The sensing module is a self-contained device that includes a battery, on-board processor, various sensors, bluetooth module and infra-red camera. "
          },
          {
            heading: "Multi-platform support",
            text:
              "The module is compatible with rail systems on most weapons and slides onto any platform in the same way as most other weapon attatchements."
          },
          {
            heading: "Rechargable",
            text:
              "The device is self-powered and is rechargable. Battery status indicator lights display battery status and the module is charged with a standard micro-usb adapter."
          }
        ]}
      />
    </Container>
  );
}
