import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Image = styled.img`
  width: 370px;
  @media (max-width: 1140px) {
    width: 230px;
    height: 340px;
  }
  @media (max-width: 799px) {
    width: 120px;
    height: 170px;
  }
`;
export const TextWrapper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-left: 90px;
  margin-right: 90px;
  @media (max-width: 1140px) {
    width: 350px;
  }
  @media (max-width: 799px) {
    width: 170px;
    margin-left: ${props => (props.reversed ? 0 : 10)}px;
    margin-right: ${props => (props.reversed ? 10 : 0)}px;
  }
`;

export const TitleText = styled.span`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
  @media (max-width: 1140px) {
    font-size: 25px;
  }
  @media (max-width: 799px) {
    font-size: 20px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.span`
  font-size: 25px;
  font-weight: 500;
  color: #ff6868;
  @media (max-width: 1140px) {
    font-size: 20px;
  }
  @media (max-width: 799px) {
    font-size: 10px;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  line-height: 30px;
  @media (max-width: 1140px) {
    font-size: 15px;
    line-height: 20px;
  }
  @media (max-width: 799px) {
    font-size: 10px;
    line-height: 15px;
  }
`;
