import styled from "styled-components";

export const Container = styled.div`
  background-image: url(${props => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding-top: 200px;
  margin-bottom: -100px;
  @media (max-width: 550px) {
    padding-top: 70px;
  }
`;

export const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Paragraph = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: black;
  width: 1000px;
  margin-top: 100px;
  @media (max-width: 1100px) {
    width: 80vw;
    font-size: 15px;
    margin-top: 50px;
  }
`;
