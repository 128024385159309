import styled from "styled-components";

export const Container = styled.div`
  background-image: url(${props => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  @media (max-width: 822px) {
    background-image: url(${props => props.bg2});
  }
`;

export const Video = styled.iframe`
  height: 315px;
  width: 560px;
  margin-bottom: 100px;
  @media (max-width: 732px) {
    height: 165px;
    width: 300px;
  }
`;
