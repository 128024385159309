import React from "react";

import Fade from "react-reveal/Fade";

import {
  Container,
  Wrapper,
  Image,
  TextWrapper,
  TitleText,
  ItemWrapper,
  Heading,
  Text,
  ImageWrapper
} from "./styles";

export default function HwBreakdown({
  reversed = false,
  image,
  title,
  items = []
}) {
  return (
    <Container>
      <Wrapper>
        <Fade left>
          <ImageWrapper>
            <Image src={image} />
          </ImageWrapper>
        </Fade>

        <Fade left={reversed} right={!reversed}>
          <TextWrapper reversed={reversed}>
            {items.map((item, index) => {
              const { heading, text } = item;
              return (
                <ItemWrapper key={index}>
                  <Heading>{heading}</Heading>
                  <Text>{text}</Text>
                </ItemWrapper>
              );
            })}
          </TextWrapper>
        </Fade>
      </Wrapper>
    </Container>
  );
}
