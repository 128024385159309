import React from "react";

import { Container } from "./styles";

import BG from "../../assets/softwareBg1.jpg";

import Analyze from "../../assets/screens/analyze.png";

import Breakdown from "../../components/breakdown";

export default function Software2() {
  return (
    <Container bg={BG}>
      <Breakdown
        reversed
        image={Analyze}
        title={"Analysis"}
        items={[
          {
            heading: "Placement",
            text:
              "Analysing historical shot data using previous target states to indicate where shots were placed for correlation with collected metrics."
          },
          {
            heading: "Historical data",
            text:
              "Session data is saved per shooter, which means that previous sessions can be managed across a large group of regular shooters for individual assessment."
          },
          {
            heading: "Graphing",
            text:
              "Data visualization through graphing allows data to be easily interpreted on various metrics such as shot placement, stability and accuracy."
          }
        ]}
      />
    </Container>
  );
}
