import React from "react";

import Fade from "react-reveal/Fade";

import { Container, Video } from "./styles";
import Bg from "../../assets/problembg.jpg";
import Bg2 from "../../assets/problembg2.jpg";

import Heading from "../../components/heading";
import Cards from "../../components/cards";

export default function TheProblem({ children }) {
  return (
    <Container bg={Bg} bg2={Bg2}>
      <Fade bottom>
        <Heading text={"THE PROBLEM"} />
      </Fade>

      <Cards />

      <Video
        src="https://www.youtube.com/embed/qQDfwyUgtjg"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      {children}
    </Container>
  );
}
