import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 900px;
  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CardItem = styled.div`
  background-color: white;
  height: 320px;
  width: 260px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
  transition: all 0.3s;
  border-top: 2px solid transparent;
  box-shadow: 2px 5px 16px 0px #00000026, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  @media (max-width: 1100px) {
    margin-top: 30px;
  }
  &:hover {
    box-shadow: 2px 5px 20px 0px #00000050, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    border-top: 2px solid #ff6868;
  }
`;

export const Topbar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.span`
  font-size: 25px;
  font-weight: 300;
`;

export const Icon = styled.img`
  height: 30px;
  width: 30px;
`;

export const ListItem = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ListIcon = styled.img`
  height: 15px;
  margin-right: 15px;
`;
