import React from "react";
import Fade from "react-reveal/Fade";

import { Container, Project, Wrapper, InfoItem, Icon, Text } from "./styles";

import Zans from "../../assets/zans.png";
import Safety from "../../assets/icons/safety.png";
import Move from "../../assets/icons/move.png";
import Feedback from "../../assets/icons/feedback.png";
import User from "../../assets/icons/user.png";

import Heading from "../../components/heading";

export default function Solution() {
  return (
    <Container>
      <Fade bottom>
        <Heading text={"THE SOLUTION"} />
      </Fade>
      <Fade bottom>
        <Project src={Zans} />
      </Fade>

      <Wrapper>
        <Fade bottom>
          <InfoItem>
            <Icon src={Safety} />
            <Text>
              An accurate, realistic and safe training system without the use of
              bullets that replicates live firing.
            </Text>
          </InfoItem>
        </Fade>
        <Fade bottom>
          <InfoItem>
            <Icon src={Move} />
            <Text>
              Track the user’s body, hand movement and trigger squeeze building
              up to each shot.
            </Text>
          </InfoItem>
        </Fade>
        <Fade bottom>
          <InfoItem>
            <Icon src={Feedback} />
            <Text>
              Live feedback in the form of realistic target which indicates
              positions of shots relative to bullseye as well as per-shot
              feedback on metrics such as accuracy, timing and positioning.
            </Text>
          </InfoItem>
        </Fade>
        <Fade bottom>
          <InfoItem>
            <Icon src={User} />
            <Text>
              Builds a user profile and saves each shooting session for later
              viewing.
            </Text>
          </InfoItem>
        </Fade>
      </Wrapper>
    </Container>
  );
}
