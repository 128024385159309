import React from "react";

import { Container } from "./styles";

export default function Footer() {
  return (
    <Container>
      2008 - Zans & Associates <br />
      <br />
      PSIRA (Private Security Industry Regulator Authority) Registration No:
      2000475 – Grade A Response.
    </Container>
  );
}
