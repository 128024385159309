import React from 'react';

import { Container, VideoWrapper, VideoContainer } from './styles';
import Fade from 'react-reveal/Fade';

import Heading from '../../components/heading';

import BG from '../../assets/softwareBg1.jpg';
import demo1 from '../../assets/videos/demo_1.mp4';
import demo2 from '../../assets/videos/demo_2.mp4';
import demo3 from '../../assets/videos/demo_3.mp4';

export default function Videos() {
  return (
    <Container bg={BG}>
      <Fade bottom>
        <Heading text={'DEMO'} />
      </Fade>
      <VideoWrapper>
        <Fade left>
          <VideoContainer controls>
            <source src={demo1} type='video/mp4' />
          </VideoContainer>
        </Fade>
        <Fade right>
          <VideoContainer controls>
            <source src={demo2} type='video/mp4' />
          </VideoContainer>
        </Fade>
        <Fade bottom>
          <VideoContainer controls>
            <source src={demo3} type='video/mp4' />
          </VideoContainer>
        </Fade>
      </VideoWrapper>
    </Container>
  );
}
