import React from "react";

import Fade from "react-reveal/Fade";

import { Container, InnerWrapper, Paragraph } from "./styles";
import Bg from "../../assets/aboutbg.jpg";

import Heading from "../../components/heading";

export default function About() {
  return (
    <Container bg={Bg}>
      <InnerWrapper>
        <Fade bottom>
          <Heading text={"WHO ARE WE"} />
        </Fade>
        <Fade bottom>
          <Paragraph>
            Established in 2008, Zans is a professional and proudly South
            African security company that specializes in all areas of executive
            protection. In 2016 our research and development department wing
            began designing a digital shooting simulator platform which provides
            a realistic training experience without the conventional costs.
          </Paragraph>
        </Fade>
      </InnerWrapper>
    </Container>
  );
}
