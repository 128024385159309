import styled from "styled-components";

export const Container = styled.div`
  height: 100px;
  width: 100vw;
  background-color: black;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: lightgrey;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
`;
