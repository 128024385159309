import styled from "styled-components";

export const Container = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Project = styled.img`
  margin-top: 100px;
  width: 600px;
  @media (max-width: 765px) {
    width: 400px;
  }
  @media (max-width: 450px) {
    width: 300px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 100px;
  width: 600px;
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    width: 90vw;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 537px) {
    flex-direction: column;
    margin-top: 40px;
  }
`;
export const Icon = styled.img`
  height: 50px;
`;
export const Text = styled.p`
  margin-left: 30px;
  line-height: 30px;
  font-size: 20px;
  @media (max-width: 485px) {
    font-size: 15px;
  }
`;
